import React, { useState, useRef, useEffect, useCallback } from "react";
import { Upload } from "lucide-react";
import { useTranslation } from "react-i18next";

export function DragAndDropTextEditor({ initialContent = "", onSetContent }) {
  const { t } = useTranslation();
  const [content, setContent] = useState(initialContent);
  const [isDragging, setIsDragging] = useState(false);
  const editorRef = useRef(null);
  const draggedItemRef = useRef(null);

  const [isInternalDrag, setIsInternalDrag] = useState(false);
  useEffect(() => {
    editorRef.current?.focus();
  }, []);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.addEventListener("keydown", (e) => {
        if (e.key === " ") {
          e.preventDefault();
          document.execCommand("insertText", false, " ");
        }
      });
    }
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);

    // Show text cursor
    const range = document.caretRangeFromPoint(e.clientX, e.clientY);
    if (range) {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, []);

  const handleDragLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const getPreviewContent = useCallback((htmlContent) => {
    // Create a temporary div to parse HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Find all draggable spans
    const spans = tempDiv.querySelectorAll('span[draggable="true"]');
    spans.forEach((span) => {
      // Remove the grip icon span
      const gripIcon = span.querySelector(".grip-icon");
      if (gripIcon) {
        gripIcon.remove();
      }

      let textContent = span.textContent || span.innerText;

      textContent = textContent.replace(/\s+/g, " ").trim();
      // Replace the span with ${content} format
      span.outerHTML = `\${${textContent}}`;
    });

    tempDiv.innerHTML = tempDiv.innerHTML.replace("&nbsp;", " ");
    return tempDiv.innerHTML;
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      setIsDragging(false);
      console.log("handleDrop");

      const selection = window.getSelection();
      if (selection.rangeCount === 0) return;

      const range = selection.getRangeAt(0);

      if (draggedItemRef.current) {
        console.log("dropper", draggedItemRef.current);
        range.deleteContents();
        range.insertNode(draggedItemRef.current);
        draggedItemRef.current = null;
      } else if (e.dataTransfer.types.includes("text/html")) {
        console.log("deneme", "deneme");
        const droppedHTML = e.dataTransfer.getData("text/html");
        const id = `draggable-${Date.now()}`;
        const wrapper = document.createElement("span");
        wrapper.innerHTML = droppedHTML;
        wrapper.id = id;
        wrapper.draggable = true;
        wrapper.contentEditable = false;

        wrapper.className = "inline-block ";
        wrapper.style.pointerEvents = "move";

        wrapper.addEventListener("drop", (e) => e.stopPropagation());

        wrapper.addEventListener("dragstart", (e) => handleDragStart(e, id));

        range.deleteContents();
        range.insertNode(wrapper);
        draggedItemRef.current = null;
      }

      var newContent = editorRef.current.innerHTML;
      newContent = newContent.replace(/&nbsp;/g, " ");
      setContent(newContent);
      onSetContent?.({
        original: newContent,
        preview: getPreviewContent(newContent),
      });

      // Move cursor after the inserted content
      const newRange = document.createRange();
      newRange.setStartAfter(range.endContainer);
      newRange.collapse(true);
      selection.removeAllRanges();
      selection.addRange(newRange);
    },
    [onSetContent, getPreviewContent]
  );

  const handleDragStart = useCallback((e, id) => {
    console.log("handleDragStart", id);
    const draggedElement = document.getElementById(id);
    draggedItemRef.current = draggedElement;
    e.dataTransfer.setData("text/plain", id);
    e.dataTransfer.effectAllowed = "move";
    setIsInternalDrag(true);

    // Create an invisible ghost image
    const ghostElement = document.createElement("div");
    ghostElement.style.width = "0";
    ghostElement.style.height = "0";
    ghostElement.style.opacity = "0";
    document.body.appendChild(ghostElement);
    e.dataTransfer.setDragImage(ghostElement, 0, 0);

    // Remove the ghost element after a short delay
    setTimeout(() => {
      document.body.removeChild(ghostElement);
    }, 0);

    // Hide the original element during drag
    draggedElement.style.opacity = "0";

    // Show the text cursor
    const cursorElement = document.createElement("div");
    cursorElement.textContent = "|";
    cursorElement.style.position = "fixed";
    cursorElement.style.pointerEvents = "none";
    cursorElement.style.zIndex = "9999";
    cursorElement.style.fontSize = "20px";
    cursorElement.style.fontWeight = "bold";
    document.body.appendChild(cursorElement);

    // Update cursor position during drag
    const updateCursorPosition = (e) => {
      cursorElement.style.left = `${e.clientX}px`;
      cursorElement.style.top = `${e.clientY}px`;
    };

    document.addEventListener("dragover", updateCursorPosition);

    // Clean up on drag end
    const handleDragEnd = () => {
      console.log("handleDragEnd");
      draggedElement.style.opacity = "1";
      document.body.removeChild(cursorElement);
      document.removeEventListener("dragover", updateCursorPosition);
      document.removeEventListener("dragend", handleDragEnd);
    };

    document.addEventListener("dragend", handleDragEnd);
  }, []);

  const handleInput = useCallback(
    (e) => {
      var newContent = e.target.innerHTML;
      setContent(newContent);
      // Send both original and preview content to parent

      newContent = newContent.replace(/&nbsp;/g, " ");
      onSetContent?.({
        original: newContent,
        preview: getPreviewContent(newContent),
      });
    },
    [onSetContent, getPreviewContent]
  );
  useEffect(() => {
    setContent(initialContent);
    if (editorRef.current) {
      editorRef.current.innerHTML = initialContent;
      onSetContent?.({
        original: initialContent,
        preview: getPreviewContent(initialContent),
      });
    }
  }, [initialContent, getPreviewContent]);

  return (
    <div className="flex flex-col relative">
      <div
        className={`w-full min-h-[60px] border-2 border-dashed border-gray-300 rounded-md p-4 outline-none`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        contentEditable
        onInput={handleInput}
        ref={editorRef}
      ></div>
      {content == "" && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-gray-500 pointer-events-none">
          {t("common.dragAndDrop")}
        </div>
      )}
    </div>
  );
}
