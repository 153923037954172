import React from "react";
import { AppLayout } from "../shared/layout";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { PlanListComponent } from "@/screens/shared/plan-list";
import { FileSpreadsheet, Mail, Check } from "lucide-react";

export function PlanScreen() {
  const { t } = useTranslation();

  return (
    <AppLayout title={t("dashboard.welcome")}>
      <div className="space-y-8">
        <Card>
          <CardHeader>
            <CardTitle>{t("dashboard.availablePlans")}</CardTitle>
          </CardHeader>
          <CardContent>
            <PlanListComponent />
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>{t("dashboard.featuredFeatures")}</CardTitle>
          </CardHeader>
          <CardContent>
            <ul className="space-y-2">
              {[
                "userFriendlyInterface",
                "fastEfficientPerformance",
                "advancedSecurity",
                "seamlessExperience",
              ].map((feature) => (
                <li key={feature} className="flex items-center">
                  <Check className="h-5 w-5 text-green-500 mr-2" />
                  <span>{t(`dashboard.${feature}`)}</span>
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>
      </div>
    </AppLayout>
  );
}
