import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthStore from "../stores/AuthStore";
import { ProtectedAdminRoute } from "./protected-admin-route";
import { getWaitingPageStatus } from "@/data/requests/setting-requests";

// Screens
import * as PublicScreens from "../screens";
import * as ProtectedScreens from "../screens";
import * as AdminScreens from "../screens";
import { NotFoundScreen } from "@/screens/shared/not-found";
import { ProtectedRoute } from "./protected-route";
import { useObserver } from "mobx-react";

const createProtectedRoute = (Component, permission = null) => (
  <ProtectedRoute>
    {permission ? (
      <ProtectedAdminRoute permission={permission}>
        <Component />
      </ProtectedAdminRoute>
    ) : (
      <Component />
    )}
  </ProtectedRoute>
);

const PublicRoutes = (
  <>
    <Route exact path="/" element={<PublicScreens.LandingPageScreen />} />
    <Route path="/about" element={<PublicScreens.AboutPageScreen />} />
    <Route path="/pricing" element={<PublicScreens.PricingPageScreen />} />
    <Route
      path="/privacy-policy"
      element={<PublicScreens.PrivacyPolicyScreen />}
    />
    <Route
      path="/login"
      element={
        !AuthStore.isLogin || AuthStore.isHaveTokenLogin() ? (
          <PublicScreens.LoginScreen />
        ) : (
          <Navigate to="/" />
        )
      }
    />
    <Route
      path="/register"
      element={
        !AuthStore.isLogin || AuthStore.isHaveTokenLogin() ? (
          <PublicScreens.RegisterScreen />
        ) : (
          <Navigate to="/" />
        )
      }
    />
    <Route
      path="/forgot-password"
      element={<PublicScreens.ForgotPasswordScreen />}
    />
    <Route
      path="/reset-password"
      element={<PublicScreens.PasswordResetScreen />}
    />
  </>
);

const ProtectedRoutes = (
  <>
    <Route
      path="/dashboard"
      element={createProtectedRoute(ProtectedScreens.HomeScreen)}
    />
    <Route
      path="/plans"
      element={createProtectedRoute(ProtectedScreens.PlanScreen)}
    />

    <Route
      path="/email-accounts"
      element={createProtectedRoute(ProtectedScreens.MailAccountListScreen)}
    />
    <Route
      path="/email-accounts/add"
      element={createProtectedRoute(ProtectedScreens.MailAccountAddScreen)}
    />
    <Route
      path="/email-accounts/:id"
      element={createProtectedRoute(ProtectedScreens.MailAccountUpdateScreen)}
    />
    <Route
      path="/documents"
      element={createProtectedRoute(ProtectedScreens.DocumentListScreen)}
    />
    <Route
      path="/document/add"
      element={createProtectedRoute(ProtectedScreens.DocumentAddScreen)}
    />
    <Route
      path="/documents/:id"
      element={createProtectedRoute(ProtectedScreens.DocumentDetailScreen)}
    />
    <Route
      path="/documents/:id/manage"
      element={createProtectedRoute(ProtectedScreens.DocumentManageScreen)}
    />
    <Route
      path="/documents/:id/send"
      element={createProtectedRoute(ProtectedScreens.DocumentSendScreen)}
    />
    <Route
      path="/documents/:id/email-transactions"
      element={createProtectedRoute(
        ProtectedScreens.DocumentMailTransactionListScreen
      )}
    />
    <Route
      path="/mail-templates"
      element={createProtectedRoute(ProtectedScreens.MailTemplateListScreen)}
    />
    <Route
      path="/mail-templates/add"
      element={createProtectedRoute(ProtectedScreens.MailTemplateManageScreen)}
    />
    <Route
      path="/mail-templates/:id"
      element={createProtectedRoute(ProtectedScreens.MailTemplateManageScreen)}
    />
    <Route
      path="/profile"
      element={createProtectedRoute(ProtectedScreens.ProfileScreen)}
    />
  </>
);

const AdminRoutes = (
  <Route path="admin">
    <Route
      index
      element={createProtectedRoute(
        AdminScreens.AdminDashboardScreen,
        "Permission_Admin_Dashboard"
      )}
    />
    <Route
      path="settings"
      element={createProtectedRoute(
        AdminScreens.SettingScreen,
        "Permission_Admin_Dashboard"
      )}
    />
    <Route
      path="users"
      element={createProtectedRoute(
        AdminScreens.UserListScreen,
        "Permission_UserRead"
      )}
    />
    <Route
      path="users/:id"
      element={createProtectedRoute(
        AdminScreens.UserManageScreen,
        "Permission_UserRead"
      )}
    />
    <Route
      path="users/new"
      element={createProtectedRoute(
        AdminScreens.UserManageScreen,
        "USER_CREATE"
      )}
    />
    <Route
      path="roles"
      element={createProtectedRoute(AdminScreens.RoleListScreen, "ROLE_READ")}
    />
    <Route
      path="roles/new"
      element={createProtectedRoute(
        AdminScreens.RoleManageScreen,
        "ROLE_CREATE"
      )}
    />
    <Route
      path="roles/:id"
      element={createProtectedRoute(
        AdminScreens.RoleManageScreen,
        "ROLE_UPDATE"
      )}
    />
    <Route
      path="plans"
      element={createProtectedRoute(
        AdminScreens.PlanListScreen,
        "Permission_PlanRead"
      )}
    />
    <Route
      path="plans/:id"
      element={createProtectedRoute(
        AdminScreens.PlanManageScreen,
        "Permission_PlanRead"
      )}
    />
    <Route
      path="plans/new"
      element={createProtectedRoute(
        AdminScreens.PlanManageScreen,
        "PLAN_CREATE"
      )}
    />
    <Route
      path="subscriptions"
      element={createProtectedRoute(
        AdminScreens.SubscriptionListScreen,
        "Permission_SubscriptionRead"
      )}
    />
    <Route
      path="waitinglist"
      element={createProtectedRoute(
        AdminScreens.WaitingListScreen,
        "Permission_WaitingListRead"
      )}
    />
    <Route
      path="email-templates"
      element={createProtectedRoute(
        AdminScreens.EmailTemplateListScreen,
        "Permission_EmailTemplateRead"
      )}
    />
    <Route
      path="email-templates/new"
      element={createProtectedRoute(
        AdminScreens.EmailTemplateManageScreen,
        "EMAIL_TEMPLATE_CREATE"
      )}
    />
    <Route
      path="email-templates/:id"
      element={createProtectedRoute(
        AdminScreens.EmailTemplateManageScreen,
        "EMAIL_TEMPLATE_UPDATE"
      )}
    />
  </Route>
);

export function BaseRouter() {
  const [waitingPageActive, setWaitingPageActive] = useState(false);

  useEffect(() => {
    const checkWaitingPageStatus = async () => {
      const response = await getWaitingPageStatus();
      setWaitingPageActive(response.data === "active");
    };

    checkWaitingPageStatus();
  }, []);

  return useObserver(() => (
    <Routes>
      {waitingPageActive ? (
        <>
          <Route exact path="*" element={<PublicScreens.WaitingPageScreen />} />
          {AdminRoutes}
        </>
      ) : (
        <>
          {PublicRoutes}
          {ProtectedRoutes}
          {AdminRoutes}
        </>
      )}
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  ));
}
