import React from "react";
import { AppLayout } from "../shared/layout";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { PlanListComponent } from "@/screens/shared/plan-list";
import { FileSpreadsheet, Mail, Check } from "lucide-react";
import { DragAndUpload } from "@/components/drag-and-upload";

export function HomeScreen() {
  const { t } = useTranslation();

  return (
    <AppLayout title={t("dashboard.welcome")}>
      <DragAndUpload className="w-full h-96" />
    </AppLayout>
  );
}
