import React from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthStore from "../../stores/AuthStore";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { User, Home, FileText, Mail, FileSpreadsheet } from "lucide-react";
import { useTranslation } from "react-i18next";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from "@/components/ui/sidebar";

const routesMenu = [
  {
    displayName: "appMenu.home",
    path: "/dashboard",
    icon: Home,
  },
  {
    displayName: "appMenu.documents",
    path: "/documents",
    icon: FileText,
  },
  {
    displayName: "appMenu.emailAccounts",
    path: "/email-accounts",
    icon: Mail,
  },
  {
    displayName: "appMenu.emailTemplates",
    path: "/mail-templates",
    icon: FileSpreadsheet,
  },
];

export function NavbarApp() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { i18n, t } = useTranslation();

  const logout = () => {
    queryClient.clear();
    AuthStore.logout();
    navigate("/");
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Sidebar>
      <SidebarHeader>
        <Link
          className="flex title-font font-medium items-center text-gray-900 p-4"
          to="/"
        >
          Send Excel
        </Link>
      </SidebarHeader>
      <SidebarContent>
        {routesMenu.map((route) => (
          <Link
            key={route.path}
            to={route.path}
            className="flex items-center px-4 py-2 text-gray-600 hover:bg-gray-100"
          >
            <route.icon className="h-5 w-5 mr-2" />
            {t(route.displayName)}
          </Link>
        ))}
      </SidebarContent>
      <SidebarFooter className="p-4">
        <div className="flex flex-col space-y-4">
          <Link to="/plans">
            <Button variant="outline" className="w-full">
              Plans
            </Button>
          </Link>

          <Select onValueChange={changeLanguage} defaultValue={i18n.language}>
            <SelectTrigger className="w-full">
              <SelectValue placeholder={t("language")} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="en">{t("english")}</SelectItem>
              <SelectItem value="tr">{t("turkish")}</SelectItem>
            </SelectContent>
          </Select>

          {AuthStore.isHavePermission("ADMIN") && (
            <Button variant="outline" asChild className="w-full">
              <Link to="/admin">Admin</Link>
            </Button>
          )}

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="w-full flex items-center">
                <Avatar className="h-8 w-8 mr-2">
                  <AvatarImage
                    src="/path-to-your-avatar-image.jpg"
                    alt="@username"
                  />
                  <AvatarFallback>
                    <User className="h-4 w-4" />
                  </AvatarFallback>
                </Avatar>
                <span>Profile</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="end" forceMount>
              <DropdownMenuLabel className="font-normal">
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-medium leading-none">username</p>
                  <p className="text-xs leading-none text-muted-foreground">
                    user@example.com
                  </p>
                </div>
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem asChild>
                <Link to="/profile">Profile</Link>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={logout}>Log out</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </SidebarFooter>
    </Sidebar>
  );
}
