import React, { useState, useEffect, useRef } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  CheckCircleIcon,
  ChevronLeft,
  ChevronRight,
  EyeIcon,
  XCircleIcon,
} from "lucide-react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getEmailTransactions,
  updateEmailTransaction,
  deleteEmailTransaction,
} from "@/data/requests/document-requests";
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Table,
} from "@/components/ui/table";
import { EmailPreview } from "./email-preview";
import { useToast } from "@/components/ui/use-toast";

export function EmailTransactionList({ from, t, id, onPreviewUpdate }) {
  const queryClient = useQueryClient();
  const [editingEmail, setEditingEmail] = useState("");
  const [page, setPage] = useState(0);
  const { toast } = useToast();

  const { data, isLoading, error } = useQuery({
    queryKey: ["email-transactions", id, page],
    queryFn: () => getEmailTransactions(id, { take: 10, skip: page * 10 }),
  });

  const updateMutation = useMutation({
    mutationFn: ({ transactionId, data }) =>
      updateEmailTransaction(id, transactionId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["email-transactions", id]);
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.response.data.message,
        variant: "destructive",
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (transactionId) => deleteEmailTransaction(id, transactionId),
    onSuccess: () => {
      queryClient.invalidateQueries(["email-transactions", id]);
    },
  });

  const handleSaveEmail = (transaction, newEmail) => {
    updateMutation.mutate({
      transactionId: transaction.id,
      data: { to: newEmail },
    });
    setEditingEmail("");
  };

  const handleDeleteEmail = (transaction) => {
    deleteMutation.mutate(transaction.id);
  };

  const handlePreviewClick = (transaction) => {
    const preview = {
      from: from,
      to: transaction.to,
      subject: transaction.subject,
      mailContent: transaction.mailContent,
    };
    onPreviewUpdate([preview]);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const transactions = data?.data || [];
  const hasMore = data?.hasMore || false;
  const totalPages = Math.ceil((data?.total || 0) / 10);

  return (
    <Card className=" h-full">
      <CardHeader>
        <CardTitle>Email Transactions</CardTitle>
      </CardHeader>
      <CardContent className="pb-16">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>To</TableHead>
              <TableHead>Email Valid</TableHead>
              <TableHead>Subject</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {transactions.map((transaction) => (
              <TableRow key={transaction.id}>
                <TableCell>
                  {transaction.isEmailValid ? (
                    transaction.to
                  ) : (
                    <div className="flex gap-2">
                      <Input
                        value={editingEmail || transaction.to}
                        onChange={(e) => setEditingEmail(e.target.value)}
                        className="w-full"
                      />
                      <Button
                        onClick={() =>
                          handleSaveEmail(
                            transaction,
                            editingEmail || transaction.to
                          )
                        }
                        disabled={updateMutation.isLoading}
                      >
                        Save
                      </Button>
                      <Button
                        variant="destructive"
                        onClick={() => handleDeleteEmail(transaction)}
                        disabled={deleteMutation.isLoading}
                      >
                        Delete
                      </Button>
                    </div>
                  )}
                </TableCell>
                <TableCell>
                  {transaction.isEmailValid ? (
                    <CheckCircleIcon className="w-4 h-4 text-green-500" />
                  ) : (
                    <XCircleIcon className="w-4 h-4 text-red-500" />
                  )}
                </TableCell>
                <TableCell>{transaction.subject}</TableCell>
                <TableCell>
                  {transaction.isSended ? "Sent" : "Pending"}
                </TableCell>
                <TableCell>
                  <Button
                    variant="ghost"
                    className="border border-gray-300"
                    onClick={() => handlePreviewClick(transaction)}
                  >
                    <EyeIcon className="w-4 h-4" />
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="absolute bottom-3 left-0 right-0 p-4 bg-white border-t flex justify-between items-center">
          <Button
            onClick={() => setPage((p) => Math.max(0, p - 1))}
            disabled={page === 0}
          >
            <ChevronLeft className="w-4 h-4 mr-2" />
            Previous
          </Button>
          <div>
            Page {page + 1} of {totalPages}
          </div>
          <Button onClick={() => setPage((p) => p + 1)} disabled={!hasMore}>
            Next
            <ChevronRight className="w-4 h-4 ml-2" />
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
