import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Controller } from "react-hook-form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { DragAndDropTextEditor } from "@/components";
import { Input } from "@/components/ui";
import { Button } from "@/components/ui/button";
import { Upload, X, Paperclip } from "lucide-react";

export function EmailSettings({
  t,
  control,
  setValue,
  isEmailAccountsLoading,
  emailAccounts,
  validateEmailHeader,
  headers,
  data,
}) {
  // File upload handling
  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      // Get existing attachments or initialize empty array
      const existingAttachments = control._formValues.attachments || [];

      // Add new files to existing attachments
      const newAttachments = [
        ...existingAttachments,
        ...files.map((file) => ({
          name: file.name,
          type: file.type,
          size: file.size,
          file: file,
        })),
      ];

      // Update form value
      setValue("attachments", newAttachments);
    }
  };

  // Remove attachment
  const removeAttachment = (index) => {
    const attachments = [...(control._formValues.attachments || [])];
    attachments.splice(index, 1);
    setValue("attachments", attachments);
  };

  return (
    <Card className="h-full">
      <CardHeader>
        <CardTitle>{t("mailCompose.emailSettings")}</CardTitle>
      </CardHeader>
      <CardContent>
        <div>
          <label className="block text-sm font-medium mb-1">
            {t("mailCompose.from")}
          </label>
          <Controller
            name="emailAccountId"
            control={control}
            render={({ field }) => {
              return (
                <select
                  className="flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    const selectedAccount = emailAccounts?.find(
                      (acc) => acc.id === parseInt(e.target.value)
                    );
                    setValue("emailAccountMail", selectedAccount?.email);
                    setValue("emailAccountName", selectedAccount?.name);
                  }}
                  value={field.value || ""}
                >
                  <option value="" disabled>
                    {t("mailCompose.selectEmailAccount")}
                  </option>
                  {isEmailAccountsLoading ? (
                    <option value="loading" disabled>
                      {t("common.loading")}
                    </option>
                  ) : emailAccounts?.length ? (
                    emailAccounts.map((account) => (
                      <option key={account.id} value={account.id.toString()}>
                        {account.name} - {account.email}
                      </option>
                    ))
                  ) : (
                    <option value="no-accounts" disabled>
                      {t("mailCompose.noEmailAccounts")}
                    </option>
                  )}
                </select>
              );
            }}
          />
        </div>

        <div className=" flex items-center mt-2">
          <label className=" text-sm font-medium mr-2 w-20">
            {t("mailCompose.to")}
          </label>
          <div className="w-full">
            <Controller
              name="to"
              control={control}
              defaultValue={data?.to}
              render={({ field }) => (
                <DragAndDropTextEditor
                  title={t("mailCompose.to")}
                  initialContent={data?.to}
                  onSetContent={({ original, preview }) => {
                    field.onChange(preview);
                    setValue("to", preview);
                  }}
                  isSingleItem={true}
                  onDropFinish={validateEmailHeader}
                  headers={headers}
                />
              )}
            />
          </div>
        </div>

        <div className=" flex items-center mt-2">
          <label className=" text-sm font-medium mr-2 w-20">
            {t("mailCompose.subject")}
          </label>
          <div className="w-full">
            <Controller
              name="subject"
              control={control}
              render={({ field }) => (
                <DragAndDropTextEditor
                  style={{ width: "100%" }}
                  title={t("mailCompose.subject")}
                  initialContent={data?.subject}
                  onSetContent={({ original, preview }) => {
                    field.onChange(preview);
                    setValue("subject", preview);
                  }}
                  isSingleItem={false}
                  headers={headers}
                />
              )}
            />
          </div>
        </div>

        {/* File Attachments Section */}
        <div className="mt-4">
          <label className="block text-sm font-medium mb-2">
            {t("mailCompose.attachments") || "Attachments"}
          </label>

          <div className="flex items-center">
            <Button
              type="button"
              variant="outline"
              className="flex items-center gap-2"
              onClick={() => document.getElementById("file-upload").click()}
            >
              <Upload className="w-4 h-4" />
              <span>{t("mailCompose.addAttachment") || "Add Attachment"}</span>
            </Button>
            <input
              id="file-upload"
              type="file"
              multiple
              className="hidden"
              onChange={handleFileUpload}
            />
          </div>

          {/* Display Attachments */}
          <Controller
            name="attachments"
            control={control}
            defaultValue={data?.attachments || []}
            render={({ field }) => (
              <div className="mt-2 space-y-2">
                {field.value && field.value.length > 0 ? (
                  field.value.map((attachment, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-2 bg-gray-50 rounded border"
                    >
                      <div className="flex items-center gap-2">
                        <Paperclip className="w-4 h-4 text-gray-500" />
                        <span className="text-sm truncate max-w-[200px]">
                          {attachment.name}
                        </span>
                        <span className="text-xs text-gray-500">
                          ({Math.round(attachment.size / 1024)} KB)
                        </span>
                      </div>
                      <Button
                        type="button"
                        variant="ghost"
                        size="sm"
                        onClick={() => removeAttachment(index)}
                      >
                        <X className="w-4 h-4 text-gray-500" />
                      </Button>
                    </div>
                  ))
                ) : (
                  <div className="text-sm text-gray-500 italic">
                    {t("mailCompose.noAttachments") || "No attachments added"}
                  </div>
                )}
              </div>
            )}
          />
        </div>
      </CardContent>
    </Card>
  );
}
