import React, { useEffect, useState } from "react";
import { NavbarApp } from "../../../components";
import { useQuery } from "@tanstack/react-query";
import { getProfile } from "../../../data";
import AuthStore from "../../../stores/AuthStore";
import { observer } from "mobx-react";
import { SidebarProvider, SidebarTrigger } from "@/components/ui/sidebar";
import { Link } from "react-router-dom";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui";

export const AppLayout = (props) => {
  const [fistTimeForMount, setFistTimeForMount] = useState(true);
  const { data, isSuccess } = useQuery({
    queryKey: ["profile"],
    queryFn: getProfile,
  });
  useEffect(() => {
    //Todo: for permission control this code writed here, use effect is not good place for this code

    if (isSuccess) {
      if (data != null) {
        if (fistTimeForMount) {
          console.log("isSuccess b", isSuccess);
          AuthStore.setProfile(data.data.user);
        } else {
          console.log("isSuccess c", isSuccess);
        }

        setFistTimeForMount(false);
      }
    }
  }, [fistTimeForMount, isSuccess, data]);

  return (
    <SidebarProvider>
      <NavbarApp />

      <main className="w-full flex flex-col min-h-screen">
        <ResizablePanelGroup direction="horizontal" className="flex-grow">
          <ResizablePanel defaultSize={75}>
            <SidebarTrigger />
            <header className="p-4 lg:flex lg:items-center lg:justify-between">
              <div className="flex-1 min-w-0">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                  {props.title}
                </h2>
              </div>
              <div className="mt-5 flex lg:mt-0 lg:ml-4">
                {props.actions != null
                  ? props.actions.map((item) => (
                      <button
                        key={item.name}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        onClick={item.onClick}
                      >
                        {item.name}
                      </button>
                    ))
                  : ""}
              </div>
            </header>
            <div className="p-2">
              {props.loading && (
                <div>
                  <h1>Loading...</h1>
                </div>
              )}

              {props.children}
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>

        <footer className="mt-auto py-4 text-center border-t">
          <Link
            to="/privacy-policy"
            className="text-blue-600 hover:text-blue-800"
          >
            Privacy Policy
          </Link>
        </footer>
      </main>
    </SidebarProvider>
  );
};
