import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { cn } from "@/lib/utils";

export function EmailPreview({
  previews,
  from,
  selectedEmailAccountMail,
  selectedEmailAccountName,
  t,
  title,
}) {
  const [previewIndex, setPreviewIndex] = useState(0);
  const [isFlashing, setIsFlashing] = useState(false);

  useEffect(() => {
    setIsFlashing(true);
    const timer = setTimeout(() => setIsFlashing(false), 500);
    return () => clearTimeout(timer);
  }, [previews]);

  const handlePrevPreview = () => {
    setPreviewIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : previews.length - 1
    );
  };

  const handleNextPreview = () => {
    setPreviewIndex((prevIndex) =>
      prevIndex < previews.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <div className="h-full flex flex-col">
      {title && <h1 className="text-2xl font-bold">{title}</h1>}
      <div
        className={cn(
          "flex-1 overflow-y-auto transition-all duration-500",
          isFlashing && "bg-blue-50"
        )}
      >
        <div className="relative p-4">
          <div
            className={cn(
              "border rounded-lg shadow-lg overflow-hidden bg-white transition-all duration-500",
              isFlashing && "ring-2 ring-blue-400"
            )}
          >
            <div className="bg-gray-200 px-4 py-2 flex justify-between items-center">
              <div className="flex items-center space-x-2">
                <div className="w-3 h-3 rounded-full bg-red-500"></div>
                <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                <div className="w-3 h-3 rounded-full bg-green-500"></div>
              </div>
              <span className="text-sm font-medium">
                {t("mailCompose.newMessage")}
              </span>
              <div className="w-12"></div>
            </div>
            <div className="p-4">
              <div className="space-y-4">
                <div className="flex items-center border-b pb-2">
                  <span className="w-16 text-sm text-gray-600">
                    {t("mailCompose.from")}:
                  </span>
                  <span className="bg-gray-300 shadow-none focus:ring-0 border-1 border-gray-500 rounded-md px-2 py-1">
                    {selectedEmailAccountName}
                  </span>
                  <span className="text-sm text-gray-600 ml-2">
                    {selectedEmailAccountMail}
                  </span>
                </div>
                <div className="flex items-center border-b pb-2">
                  <span className="w-16 text-sm text-gray-600">
                    {t("mailCompose.to")}:
                  </span>
                  <Input
                    type="text"
                    className="flex-grow bg-transparent border-none shadow-none focus:ring-0"
                    value={previews[previewIndex]?.to || ""}
                    readOnly
                  />
                </div>
                <div className="flex items-center border-b pb-2">
                  <span className="w-16 text-sm text-gray-600">
                    {t("mailCompose.subject")}:
                  </span>
                  <Input
                    type="text"
                    className="flex-grow bg-transparent border-none shadow-none focus:ring-0"
                    value={previews[previewIndex]?.subject || ""}
                    readOnly
                  />
                </div>
                <div className="h-[calc(100vh-26rem)] overflow-y-auto">
                  <div
                    className="prose max-w-none"
                    dangerouslySetInnerHTML={{
                      __html: previews[previewIndex]?.mailContent || "",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {previews.length > 1 && (
        <div className="p-4 border-t bg-white flex justify-between items-center">
          <Button
            onClick={handlePrevPreview}
            variant="outline"
            className="flex items-center space-x-2 px-4"
          >
            <ChevronLeft className="w-4 h-4" />
            <span>Previous</span>
          </Button>
          <span className="text-sm text-gray-500">
            {previewIndex + 1} / {previews.length}
          </span>
          <Button
            onClick={handleNextPreview}
            variant="outline"
            className="flex items-center space-x-2 px-4"
          >
            <span>Next</span>
            <ChevronRight className="w-4 h-4" />
          </Button>
        </div>
      )}
    </div>
  );
}
