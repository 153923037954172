import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { login } from "../../../data";
import AuthStore from "../../../stores/AuthStore";
import {
  Button,
  Input,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui";
import { Chrome, UserPlus } from "lucide-react"; // UserPlus ikonunu da ekliyoruz

export function LoginScreen() {
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    mutate: loginUser,
    isLoading,
    error,
  } = useMutation({
    mutationKey: "login",
    mutationFn: (data) => login(data),
    onSuccess: (response) => {
      AuthStore.setUser(response.data.token);
      navigate("/dashboard");
    },
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    if (token) {
      AuthStore.setUser(token);
      navigate("/dashboard");
    }
  }, [location, navigate]);

  const onSubmit = (data) => {
    loginUser(data);
  };

  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}auth/google`;
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold mb-8 text-blue-600">Send Excel</h1>
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle>Giriş Yap</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <Controller
              name="email"
              control={control}
              rules={{ required: "E-posta gereklidir" }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="email"
                  placeholder="E-posta"
                  error={errors.email?.message}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: "Şifre gereklidir" }}
              render={({ field }) => (
                <Input
                  {...field}
                  type={showPassword ? "text" : "password"}
                  placeholder="Şifre"
                  error={errors.password?.message}
                />
              )}
            />
            <Button type="submit" className="w-full" disabled={isLoading}>
              {isLoading ? "Giriş yapılıyor..." : "Giriş Yap"}
            </Button>
          </form>

          <div className="mt-4">
            <Button
              type="button"
              variant="outline"
              className="w-full flex items-center justify-center"
              onClick={handleGoogleLogin}
            >
              <Chrome className="mr-2 h-4 w-4" />
              Google ile Giriş Yap
            </Button>
          </div>
          <div className="mt-4">
            <Button type="button" variant="link" className="w-full" asChild>
              <Link to="/forgot-password">Şifremi Unuttum</Link>
            </Button>
          </div>

          {error && <p className="text-red-500 mt-2">{error.message}</p>}
        </CardContent>
        <CardFooter className="flex justify-center">
          <Button variant="link" className="flex items-center" asChild>
            <Link to="/register">
              <UserPlus className="mr-2 h-4 w-4" />
              Hesap Oluştur
            </Link>
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}
