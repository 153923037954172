import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { NavbarLandingPage } from "../../../components";
import { useSettings } from "@/context/SettingsProvider";

export const LandingPageLayout = (props) => {
  const settingsStore = useSettings();
  const { settings } = settingsStore;

  console.log("Ayarlar:", settings); // Ayarları kontrol etmek için log eklendi

  return (
    <>
      <Helmet>
        <title>{settings.site_title}</title>
        <meta name="description" content={settings.site_description} />
        <link rel="icon" href={settings.site_icon} />
      </Helmet>

      <NavbarLandingPage />
      <main className=" ">{props.children}</main>
      <footer className="py-6 border-t">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="text-sm text-gray-600">
              Send Excel © {new Date().getFullYear()}
            </div>
            <div className="flex space-x-4">
              <Link
                to="/privacy-policy"
                className="text-sm text-blue-600 hover:text-blue-800"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
