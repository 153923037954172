import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { AppLayout } from "../shared/layout";
import {
  getAppEmailTemplates,
  getDocument,
  createEmailTransaction,
  getEmailTransactionsCount,
  updateDocument,
  getEmailTransactions,
} from "../../../data";
import { AdvancedEmailEditor } from "../../../components/mail/advance-email-editor";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Badge } from "@/components/ui/badge";

import { DragAndDropTextEditor } from "@/components";
import {
  ChartNoAxesColumn,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  Clock,
  FileSpreadsheet,
  Mail,
  Users,
  Inbox,
  Send,
  AlertCircle,
  Info,
  RefreshCw,
} from "lucide-react";
import { DocumentSteps } from "../../../components";
import { GripVertical, Tag } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { isValidEmail } from "../../../lib/utils";
import { sendMail } from "@/data/requests/document-requests";
import { useTranslation } from "react-i18next";
import { MailCompose } from "../../../components/mail-compose";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  DocumentComponents,
  DocumentTemplates,
  EmailSettings,
  EmailPreview,
} from "./components";

import { EmailTransactionList } from "./components/email-transaction-list";

export function DocumentSendScreen() {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const { t } = useTranslation();
  const [previews, setPreviews] = useState([]);

  const formRef = useRef(null);
  const { data, isLoading, error } = useQuery({
    queryKey: ["document", id],
    queryFn: () => getDocument(id),
  });

  const { data: documentDetails } = useQuery({
    queryKey: ["email-transactions", id],
    queryFn: () => getEmailTransactionsCount(id),
  });

  const createMailTransationMutation = useMutation({
    mutationFn: () => createEmailTransaction(id),
    onSuccess: () => {
      toast({
        title: t("documentDetails.transactionCreated"),
        description: t("documentDetails.transactionCreatedDescription"),
      });
      queryClient.invalidateQueries(["email-transactions", id]);
    },
  });

  const replaceTemplateVariables = (template, data) => {
    return template.replace(/\$\{([^}]+)\}/g, (match, key) => {
      const foundValue = data.find((item) => item.header.name === key);
      return foundValue ? foundValue.value : match;
    });
  };

  const generatePreviews = () => {
    if (data && data.rows && data.rows.length > 0) {
      const previewData = data.rows.slice(0, 3).map((row) => {
        const rowData = row.rowValues;
        const processedContent = data?.mailContent.replace(
          /\${([^}]+)}/g,
          (match, key) => {
            const foundValue = rowData.find((item) => item.header.name === key);
            return foundValue ? foundValue.value : match;
          }
        );

        return {
          subject: replaceTemplateVariables(data?.subject, rowData),
          to: replaceTemplateVariables(data?.to, rowData),
          mailContent: processedContent,
        };
      });
      setPreviews(previewData);
    }
  };

  useEffect(() => {
    generatePreviews();
  }, [data]);

  const createTransactions = async () => {
    createMailTransationMutation.mutate(id);
  };

  const sendMailMutation = useMutation({
    mutationFn: () => sendMail(id),
    onSuccess: () => {
      toast({
        title: t("documentDetails.emailSent"),
        description: t("documentDetails.emailSentDescription"),
      });
      queryClient.invalidateQueries(["document", id]);
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        title: t("documentDetails.emailSent"),
        description: error.response.data.message,
      });
    },
  });

  const sendMailToAll = async () => {
    sendMailMutation.mutate();
  };

  if (isLoading) return <div className="p-8">{t("common.loading")}</div>;
  if (error) return <div className="p-8">{t("common.error")}</div>;

  return (
    <div className="h-screen w-screen bg-gradient-to-br from-slate-50 to-gray-100">
      <ResizablePanelGroup className="h-screen w-screen" direction="horizontal">
        {/* Preview Section - Left */}
        <ResizablePanel defaultSize={35}>
          <div className="h-full p-6">
            <Card className="h-full border-none shadow-xl bg-white rounded-xl overflow-hidden">
              <CardHeader className="border-b bg-white/50 backdrop-blur-sm">
                <CardTitle className="text-xl font-semibold text-gray-800">
                  {t("mailCompose.preview")}
                </CardTitle>
              </CardHeader>
              <CardContent className="p-0 h-[calc(100%-4rem)]">
                <EmailPreview
                  previews={previews}
                  t={t}
                  from={data?.from}
                  selectedEmailAccountMail={data?.emailAccount?.email}
                  selectedEmailAccountName={data?.emailAccount?.name}
                />
              </CardContent>
            </Card>
          </div>
        </ResizablePanel>
        <ResizableHandle withHandle />

        {/* Transaction List Section - Middle */}
        <ResizablePanel defaultSize={45}>
          <div className="h-full p-6">
            <Card className="h-full border-none shadow-xl bg-white rounded-xl overflow-hidden">
              <CardHeader className="border-b bg-white/50 backdrop-blur-sm">
                <div className="flex justify-between items-center">
                  <CardTitle className="text-xl font-semibold text-gray-800">
                    {t("mailCompose.emailTransactions")}
                  </CardTitle>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={createTransactions}
                    className="flex items-center space-x-2"
                  >
                    <CheckCircle className="w-4 h-4" />
                    <span>
                      {documentDetails?.emailTransactionsCount > 0
                        ? t("mailCompose.refreshTransactions")
                        : t("mailCompose.createTransactions")}
                    </span>
                  </Button>
                </div>
              </CardHeader>
              <CardContent className="p-4 h-[calc(100%-4rem)] overflow-auto relative">
                {documentDetails?.emailTransactionsCount === 0 ? (
                  <div className="absolute inset-0 flex flex-col items-center justify-center p-4">
                    <Mail className="w-12 h-12 text-gray-400 mb-4" />
                    <p className="text-lg font-medium text-gray-500 mb-6">
                      {t("mailCompose.noTransactions")}
                    </p>
                    <Button
                      onClick={createTransactions}
                      className="h-12 px-6 text-base font-semibold bg-gradient-to-r from-violet-500 to-purple-500 hover:from-violet-600 hover:to-purple-600 shadow-lg shadow-violet-500/25"
                    >
                      <div className="flex items-center justify-center space-x-2">
                        <CheckCircle className="w-5 h-5" />
                        <span>{t("mailCompose.createTransactions")}</span>
                      </div>
                    </Button>
                  </div>
                ) : (
                  <div className="space-y-4">
                    <EmailTransactionList
                      id={id}
                      t={t}
                      from={data?.from}
                      onPreviewUpdate={setPreviews}
                    />
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        </ResizablePanel>
        <ResizableHandle withHandle />

        {/* Info & Actions Section - Right */}
        <ResizablePanel defaultSize={20}>
          <div className="h-full flex flex-col bg-white shadow-lg">
            <div className="p-8 flex-1 overflow-auto">
              {/* Document Info Card */}
              <Card className="mb-8 border-none shadow-xl bg-gradient-to-br from-blue-50 via-indigo-50 to-violet-50">
                <CardHeader>
                  <div className="flex items-center space-x-2">
                    <div className="h-8 w-1 bg-blue-500 rounded-full" />
                    <CardTitle className="text-xl font-semibold text-gray-800">
                      {t("documentDetails.title")}
                    </CardTitle>
                  </div>
                </CardHeader>
                <CardContent className="p-6">
                  <div className="space-y-6">
                    <div className="flex items-center justify-between p-4 bg-white rounded-xl shadow-sm border border-blue-100/50">
                      <div className="flex items-center space-x-4">
                        <div className="p-3 bg-blue-50 rounded-lg">
                          <FileSpreadsheet className="w-6 h-6 text-blue-600" />
                        </div>
                        <div>
                          <p className="text-sm font-medium text-gray-500">
                            {t("documentDetails.name")}
                          </p>
                          <p className="text-base font-semibold text-gray-800">
                            {data?.name}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center justify-between p-4 bg-white rounded-xl shadow-sm border border-blue-100/50">
                      <div className="flex items-center space-x-4">
                        <div className="p-3 bg-blue-50 rounded-lg">
                          <Users className="w-6 h-6 text-blue-600" />
                        </div>
                        <div>
                          <p className="text-sm font-medium text-gray-500">
                            {t("documentDetails.rowCount")}
                          </p>
                          <p className="text-base font-semibold text-gray-800">
                            {documentDetails?.emailTransactionsCount}{" "}
                            {t("mailCompose.recipients")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>

              {/* Email Status Card */}
              <Card className="mb-8 border-none shadow-xl bg-gradient-to-br from-emerald-50 via-teal-50 to-cyan-50">
                <CardHeader>
                  <div className="flex items-center space-x-2">
                    <div className="h-8 w-1 bg-emerald-500 rounded-full" />
                    <CardTitle className="text-xl font-semibold text-gray-800">
                      {t("mailCompose.emailStatus")}
                    </CardTitle>
                  </div>
                </CardHeader>
                <CardContent className="p-6">
                  <div className="space-y-6">
                    <div className="flex items-center justify-between p-4 bg-white rounded-xl shadow-sm border border-emerald-100/50">
                      <div className="flex items-center space-x-4">
                        <div className="p-3 bg-emerald-50 rounded-lg">
                          <Send className="w-6 h-6 text-emerald-600" />
                        </div>
                        <div>
                          <p className="text-sm font-medium text-gray-500">
                            {t("mailCompose.sentEmails")}
                          </p>
                          <p className="text-base font-semibold text-gray-800">
                            {documentDetails?.sentCount} /{" "}
                            {documentDetails?.emailTransactionsCount}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center space-x-2">
                        <Badge
                          variant="outline"
                          className="px-4 py-1.5 bg-emerald-50 text-emerald-700 border-emerald-200"
                        >
                          {Math.round(
                            (documentDetails?.sentCount /
                              documentDetails?.emailTransactionsCount) *
                              100
                          )}
                          %
                        </Badge>
                      </div>
                    </div>

                    <div className="flex items-center justify-between p-4 bg-white rounded-xl shadow-sm border border-emerald-100/50">
                      <div className="flex items-center space-x-4">
                        <div className="p-3 bg-emerald-50 rounded-lg">
                          <Inbox className="w-6 h-6 text-emerald-600" />
                        </div>
                        <div>
                          <p className="text-sm font-medium text-gray-500">
                            {t("mailCompose.remainingEmails")}
                          </p>
                          <p className="text-base font-semibold text-gray-800">
                            {documentDetails?.emailTransactionsCount -
                              documentDetails?.sentCount}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>

              {/* Attachments Section */}
              <Card className="mb-8 border-none shadow-xl bg-gradient-to-br from-amber-50 via-yellow-50 to-orange-50">
                <CardHeader>
                  <div className="flex items-center space-x-2">
                    <div className="h-8 w-1 bg-amber-500 rounded-full" />
                    <CardTitle className="text-xl font-semibold text-gray-800">
                      {t("mailCompose.attachments") || "Attachments"}
                    </CardTitle>
                  </div>
                </CardHeader>
                <CardContent className="p-6">
                  {data?.attachments && data.attachments.length > 0 ? (
                    <div className="space-y-6">
                      {data.attachments.map((attachment, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-between p-4 bg-white rounded-xl shadow-sm border border-amber-100/50"
                        >
                          <div className="flex items-center space-x-4">
                            <div className="p-3 bg-amber-50 rounded-lg">
                              {attachment.type?.includes("image") ? (
                                <img
                                  src="/icons/image-icon.svg"
                                  alt="Image"
                                  className="w-6 h-6"
                                />
                              ) : attachment.type?.includes("pdf") ? (
                                <img
                                  src="/icons/pdf-icon.svg"
                                  alt="PDF"
                                  className="w-6 h-6"
                                />
                              ) : attachment.type?.includes("word") ||
                                attachment.name?.endsWith(".doc") ||
                                attachment.name?.endsWith(".docx") ? (
                                <img
                                  src="/icons/word-icon.svg"
                                  alt="Word"
                                  className="w-6 h-6"
                                />
                              ) : attachment.type?.includes("excel") ||
                                attachment.name?.endsWith(".xls") ||
                                attachment.name?.endsWith(".xlsx") ? (
                                <img
                                  src="/icons/excel-icon.svg"
                                  alt="Excel"
                                  className="w-6 h-6"
                                />
                              ) : (
                                <img
                                  src="/icons/file-icon.svg"
                                  alt="File"
                                  className="w-6 h-6"
                                />
                              )}
                            </div>
                            <div>
                              <p className="text-sm font-medium text-gray-500">
                                {t("documentDetails.fileName") || "File Name"}
                              </p>
                              <p className="text-base font-semibold text-gray-800">
                                {attachment.name}
                              </p>
                              <p className="text-xs text-gray-500 mt-1">
                                {data?.name}
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center space-x-2">
                            <Badge
                              variant="outline"
                              className="px-4 py-1.5 bg-amber-50 text-amber-700 border-amber-200"
                            >
                              {attachment.size
                                ? `${Math.round(attachment.size / 1024)} KB`
                                : ""}
                            </Badge>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center py-8 px-4 bg-white/50 rounded-xl border border-amber-100/50">
                      <p className="text-base font-medium text-gray-700 mb-2">
                        {t("documentDetails.noAttachments") ||
                          "No attachments available"}
                      </p>
                      <p className="text-sm text-gray-500 text-center">
                        {t("documentDetails.documentName") || "Document"}:{" "}
                        {data?.name}
                      </p>
                    </div>
                  )}
                </CardContent>
              </Card>
            </div>

            {/* Action Buttons */}
            <div className="p-6 border-t bg-white">
              <Button
                onClick={sendMailToAll}
                className="w-full h-12 text-base font-semibold bg-gradient-to-r from-blue-500 to-indigo-500 hover:from-blue-600 hover:to-indigo-600 shadow-lg shadow-blue-500/25"
              >
                <div className="flex items-center justify-center space-x-2">
                  <Mail className="w-5 h-5" />
                  <span>{t("mailCompose.sendMail")}</span>
                </div>
              </Button>
            </div>
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
}
