import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { GripVertical } from "lucide-react";

export function DocumentComponents({ headers, getContrastYIQ, t }) {
  return (
    <Card className="h-full">
      <CardHeader>
        <CardTitle>{t("documentList.components")}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-2 border p-4 rounded-md">
          <h3 className="text-sm font-medium">Headers</h3>
          <div className="flex flex-wrap gap-2 mb-4 ">
            {headers?.map((header, index) => {
              const colors = [
                "#FF5733",
                "#33FF57",
                "#3357FF",
                "#FF33A1",
                "#A133FF",
                "#33FFF5",
                "#FF8C33",
                "#8CFF33",
                "#338CFF",
                "#FF338C",
              ];
              const backgroundColor = colors[index % colors.length];
              const textColor = getContrastYIQ(backgroundColor);
              return (
                <span
                  key={header.id}
                  className="flex items-center px-2 py-1 border rounded-md cursor-move w-fit"
                  draggable
                  droppable={false}
                  contentEditable={false}
                  onDragStart={(e) => {
                    const wrapper = document.createElement("div");
                    wrapper.innerHTML = `
                      <span 
                        class="header-token flex items-center px-2 py-1 border rounded-md"
                        data-header-name="${header.name}"
                        style="background-color: ${backgroundColor}; color: ${textColor};"
                      >
                        <span class="grip-icon mr-2">⋮</span>
                        ${header.name}
                      </span>
                    `;
                    e.dataTransfer.setData("text/html", wrapper.innerHTML);
                  }}
                  style={{ backgroundColor, color: textColor }}
                >
                  <GripVertical className="w-4 h-4 mr-2" />
                  {header.name}
                </span>
              );
            })}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
