import React, { useEffect, useRef, useState, version } from "react";
import grapesjs from "grapesjs";
import gjsPresetNewsletter from "grapesjs-preset-newsletter";
import "grapesjs/dist/css/grapes.min.css";
import "./ma.css";

import { Button, Input, Spinner } from "../ui";
import { generateHtml } from "@/data/requests/ai-requests";
import { Phone, Tablet, Monitor } from "lucide-react";
import { Spinner as CustomSpinner } from "../ui/spinner";
import { SpinnerOverlay } from "../ui/spinner-overlay";

import styleToCSS from "style-to-css";

export function AdvancedEmailEditor({
  content,
  onSetContent,

  minHeight,
  headers,
}) {
  const editorRef = useRef(null);
  const [editor, setEditor] = useState(null);
  const [selectedHtml, setSelectedHtml] = useState("");
  const [chatGptPrompt, setChatGptPrompt] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Editor initialization
  useEffect(() => {
    if (!editorRef.current) {
      const e = grapesjs.init({
        container: "#gjs",

        storageManager: false,

        plugins: [gjsPresetNewsletter],
        pluginsOpts: {
          "grapesjs-preset-newsletter": {},
        },

        // Enable editing for all text components
        domComponents: {
          defaults: {
            editable: true,
            droppable: true,
            draggable: true,
            selectable: true,
            hoverable: true,
          },
        },

        // Add device manager configuration
        deviceManager: {
          devices: [
            {
              name: "Desktop",
              width: "", // Default width
            },
            {
              name: "Tablet",
              width: "768px",
              widthMedia: "768px",
            },
            {
              name: "Mobile",
              width: "320px",
              widthMedia: "320px",
            },
          ],
        },
      });

      e.on("load", () => {
        setEditor(e);
        editorRef.current = e;

        const panelManager = e.Panels;

        panelManager.getPanelsEl().remove();

        // Enable dragging for text components
        const textComponents = [
          "text",
          "paragraph",
          "heading",
          "heading1",
          "heading2",
          "heading3",
          "heading4",
          "heading5",
          "heading6",
        ];
        textComponents.forEach((type) => {
          e.DomComponents.addType(type, {
            model: {
              defaults: {
                draggable: true,
                droppable: true,
              },
            },
          });
        });
      });
    }

    return () => {
      editorRef.current?.destroy();
      editorRef.current = null;
    };
  }, [minHeight]);

  // Editor configuration and content setting
  useEffect(() => {
    if (editor) {
      editor.on("component:selected", (component) => {
        if (component) {
          setSelectedHtml(component.toHTML());
        }
      });

      editor.on("component:updated", (component) => {
        if (component === editor.getSelected()) {
          setSelectedHtml(component.toHTML());
        }
      });

      // Handle template addition
      editor.on("block:drag:stop", (component) => {
        if (component && component.get("type") === "template") {
          const content = component.get("content");
          if (content) {
            editor.setComponents(content);
            // Remove the template component itself
            component.remove();
          }
        }
      });

      editor.on("component:update", () => {
        updateContent();
      });

      editor.on("component:add", () => {
        updateContent();
      });

      editor.on("component:remove", () => {
        updateContent();
      });

      // Set initial content
      if (content) {
        editor.setComponents(content);
        updateContent();
      }
    }
  }, [editor, content]);

  const updateContent = () => {
    //this get not inline last html
    var html = editor.runCommand("gjs-get-inlined-html");

    onSetContent(html);
  };

  const askChatGptForHtml = async () => {
    if (!chatGptPrompt || !editor) return;

    try {
      setIsLoading(true);

      const response = await generateHtml({
        prompt: `${chatGptPrompt}. ${
          headers
            ? ` My contains headers: ${headers
                .map((h) => `\${${h.name}}`)
                .join(", ")}.`
            : ""
        }. My current html is : ${selectedHtml}`,
      });

      var html = response.data;

      console.log("response", response);

      const selectedComponent = editor.getSelected();

      console.log("selectedComponent", selectedComponent);
      if (selectedComponent) {
        selectedComponent.components("");

        // Yeni içeriği ekle
        selectedComponent.components(html);

        editor.refresh();
      }
    } catch (error) {
      console.error("Error with Assistant API:", error);
    } finally {
      setIsLoading(false);
      updateContent();
    }
  };

  // Add device switching handlers
  const handleDeviceSwitch = (deviceName) => {
    if (editor) {
      editor.setDevice(deviceName);
    }
  };

  const editorConfig = {
    // ... existing config ...

    // Özel formatları koru
    extended_valid_elements: "span[*]",
    custom_elements: "span",

    // Drop işlemini özelleştir
    paste_preprocess: function (plugin, args) {
      // Yapıştırılan içerikte header-token class'ını koru
      const content = args.content;
      if (content.includes("header-token")) {
        args.content = content;
      }
    },

    // Editör içi stil tanımlamaları
    content_style: `
      .header-token {
        display: inline-flex;
        align-items: center;
        padding: 2px 8px;
        border-radius: 6px;
        margin: 0 2px;
        cursor: default;
      }
      .grip-icon {
        margin-right: 8px;
        opacity: 0.7;
      }
    `,

    // Header token'larının düzenlenmesini engelle
    setup: (editor) => {
      editor.on("BeforeSetContent", (e) => {
        if (e.content.includes("header-token")) {
          e.content = e.content.replace(
            /contenteditable=".*?"/g,
            'contenteditable="false"'
          );
        }
      });

      editor.on("NodeChange", (e) => {
        const headerTokens = editor.dom.select(".header-token");
        headerTokens.forEach((token) => {
          token.contentEditable = false;
        });
      });
    },
  };

  return (
    <div className="flex flex-col gap-4 h-screen justify-between">
      <SpinnerOverlay show={isLoading} />

      <div className="flex justify-center gap-4 mt-2 mb-4">
        <button
          onClick={() => handleDeviceSwitch("Mobile")}
          className="flex items-center gap-2 border border-gray-200 rounded-md p-2 hover:bg-gray-50"
        >
          <Phone className="w-4 h-4" />
          <p className="text-sm font-medium">Mobile</p>
        </button>
        <button
          onClick={() => handleDeviceSwitch("Tablet")}
          className="flex items-center gap-2 border border-gray-200 rounded-md p-2 hover:bg-gray-50"
        >
          <Tablet className="w-4 h-4" />
          <p className="text-sm font-medium">Tablet</p>
        </button>
        <button
          onClick={() => handleDeviceSwitch("Desktop")}
          className="flex items-center gap-2 border border-gray-200 rounded-md p-2 hover:bg-gray-50"
        >
          <Monitor className="w-4 h-4" />
          <p className="text-sm font-medium">Desktop</p>
        </button>
      </div>
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div
          id="gjs"
          style={{
            flexGrow: 1,
            minHeight: minHeight || "300px",
            height: "100%",
          }}
        ></div>
        <div className="mt-4 flex items-center justify-center mb-4">
          <div className="flex items-center gap-2">
            <Input
              value={chatGptPrompt}
              c
              onChange={(e) => setChatGptPrompt(e.target.value)}
              placeholder="Enter prompt for ChatGPT"
              className="input w-full min-w-[300px]"
              disabled={isLoading}
            />
            <Button
              type="button"
              onClick={askChatGptForHtml}
              className="button flex items-center gap-2"
              disabled={isLoading}
            >
              {isLoading ? (
                <CustomSpinner size="sm" />
              ) : (
                <Monitor className="w-4 h-4" />
              )}
              {isLoading ? "Generating..." : "Ask ChatGPT"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
