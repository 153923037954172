import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { AppLayout } from "../shared/layout";
import {
  getAppEmailTemplates,
  getDocument,
  createEmailTransaction,
  updateDocument,
  uploadAttachments,
} from "../../../data";
import { AdvancedEmailEditor } from "../../../components/mail/advance-email-editor";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Badge } from "@/components/ui/badge";
import { getEmailAccounts } from "../../../data";
import { DragAndDropTextEditor } from "@/components";
import {
  ChartNoAxesColumn,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  Clock,
  FileSpreadsheet,
  Mail,
  Users,
} from "lucide-react";
import { DocumentSteps } from "../../../components";
import { GripVertical, Tag } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { isValidEmail } from "../../../lib/utils";
import { sendMail } from "@/data/requests/document-requests";
import { useTranslation } from "react-i18next";
import { MailCompose } from "../../../components/mail-compose";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  DocumentComponents,
  DocumentTemplates,
  EmailSettings,
  EmailPreview,
} from "./components";

export function DocumentDetailScreen() {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const { t } = useTranslation();
  const [previews, setPreviews] = useState([]);

  const formRef = useRef(null);
  const { data, isLoading, error } = useQuery({
    queryKey: ["document", id],
    queryFn: () => getDocument(id),
  });
  const { data: emailAccounts, isLoading: isEmailAccountsLoading } = useQuery({
    queryKey: ["emailAccounts"],
    queryFn: getEmailAccounts,
  });

  const updateDocumentMutation = useMutation({
    mutationFn: (documentData) => updateDocument(id, documentData),
    onSuccess: () => {
      queryClient.invalidateQueries(["document", id]);
    },
  });

  const [showSaveDialog, setShowSaveDialog] = useState(false);

  const triggerSaveDocument = () => {
    formRef.current.click();
    setShowSaveDialog(true);
  };

  const { control, handleSubmit, watch, setValue, register } = useForm({
    defaultValues: {
      name: "",
      rowCount: "",
      headerCount: "",
      emailAccountId: "",
      emailAccount: "",
      subject: "",
      to: "",
      from: "",
      mailContent: "",
      emailAccountMail: "",
    },
  });

  const subject = watch("subject");
  const to = watch("to");
  const mailContent = watch("mailContent");
  const emailAccountMail = watch("emailAccountMail");
  const emailAccountName = watch("emailAccountName");

  const { data: templates, isLoading: isTemplatesLoading } = useQuery({
    queryKey: ["templates"],
    queryFn: getAppEmailTemplates,
  });

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const replaceTemplateVariables = (template, data) => {
    return template.replace(/\$\{([^}]+)\}/g, (match, key) => {
      const foundValue = data.find((item) => item.header.name === key);
      return foundValue ? foundValue.value : match;
    });
  };

  const generatePreviews = () => {
    if (data && data.rows && data.rows.length > 0) {
      const previewData = data.rows.slice(0, 3).map((row) => {
        const rowData = row.rowValues;
        const processedContent = mailContent.replace(
          /\${([^}]+)}/g,
          (match, key) => {
            const foundValue = rowData.find((item) => item.header.name === key);
            return foundValue ? foundValue.value : match;
          }
        );

        return {
          subject: replaceTemplateVariables(subject, rowData),
          to: replaceTemplateVariables(to, rowData),
          mailContent: processedContent,
        };
      });
      console.log("Generated Previews:", previewData);
      setPreviews(previewData);
    }
  };

  useEffect(() => {
    if (data) {
      setValue("subject", data.subject || "");
      setValue("to", data.to || "");
      setValue(
        "emailAccountId",
        data.emailAccountId ? data.emailAccountId.toString() : ""
      );
      setValue("mailContent", data.mailContent || "");
    }
    if (data?.emailAccountId && emailAccounts) {
      const account = emailAccounts.find(
        (acc) => acc.id === data.emailAccountId
      );
      if (account) {
        setValue("emailAccountMail", account.email || "");
        setValue("emailAccountName", account.name || "");
      }
    }
  }, [data, emailAccounts, setValue]);

  useEffect(() => {
    generatePreviews();
  }, [subject, to, mailContent, emailAccountMail, emailAccountName]);

  const validateEmailHeader = (headerName) => {
    console.log("validateEmailHeader", headerName);
    var validCount = 0;
    var invalidCount = 0;
    if (data && data.rows) {
      data.rows[0].rowValues.forEach((row) => {
        const email = row.header.name == headerName ? row.value : "";
        if (email) {
          console.log("email", email);
          if (isValidEmail(email)) {
            validCount++;
          } else {
            invalidCount++;
          }
        }
      });
    }
    console.log("validCount", validCount);
    console.log("invalidCount", invalidCount);
    try {
      if (invalidCount > 0) {
        toast({
          variant: "destructive",
          title: t("documentDetails.emailValidation"),
          description: t("documentDetails.invalidEmailAddresses"),
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSubmit = async (formData) => {
    console.log(formData);

    // Handle file attachments
    if (formData.attachments && formData.attachments.length > 0) {
      // Create FormData to handle file uploads
      const fileFormData = new FormData();

      // Track which attachments need to be uploaded
      const newAttachments = [];
      const existingAttachments = [];

      // Add each file to FormData
      formData.attachments.forEach((attachment, index) => {
        if (attachment.file) {
          fileFormData.append(`files`, attachment.file);
          newAttachments.push(index);
        } else {
          // If no file property, it's an existing attachment
          existingAttachments.push(attachment);
        }
      });

      // Only upload if there are new files
      if (newAttachments.length > 0) {
        try {
          // Call the API to upload files using the new function
          const result = await uploadAttachments(id, fileFormData);

          // Combine existing attachments with newly uploaded ones
          formData.attachments = [
            ...existingAttachments,
            ...result.attachments,
          ];
        } catch (error) {
          console.error("Error uploading attachments:", error);
          toast({
            variant: "destructive",
            title: t("documentDetails.attachmentError"),
            description: t("documentDetails.failedToUploadAttachments"),
          });

          // Continue with existing attachments only
          formData.attachments = existingAttachments;
        }
      }
    }

    // Update the document with all data including attachment references
    updateDocumentMutation.mutate(formData);
  };

  const replaceHeaderTokens = (content) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;

    const headerTokens = tempDiv.querySelectorAll(".header-token");
    headerTokens.forEach((token) => {
      const headerName = token.getAttribute("data-header-name");
      token.outerHTML = `\${${headerName}}`;
    });

    return tempDiv.innerHTML;
  };

  if (isLoading)
    return (
      <AppLayout>
        <div>{t("documentDetails.loading")}</div>
      </AppLayout>
    );
  if (error)
    return (
      <AppLayout>
        <div>
          {t("common.error")}: {error.message}
        </div>
      </AppLayout>
    );
  const getContrastYIQ = (hexcolor) => {
    const r = parseInt(hexcolor.slice(1, 3), 16);
    const g = parseInt(hexcolor.slice(3, 5), 16);
    const b = parseInt(hexcolor.slice(5, 7), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "black" : "white";
  };

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(onSubmit)}
      className="h-screen overflow-hidden bg-gradient-to-br from-slate-50 to-gray-100"
    >
      <ResizablePanelGroup
        direction="horizontal"
        className="min-h-screen border rounded-lg "
      >
        <ResizablePanel defaultSize={15} minSize={10} className="border-r">
          <ResizablePanelGroup direction="vertical">
            <ResizablePanel defaultSize={50} className="p-2 space-y-4">
              <DocumentComponents
                t={t}
                headers={data?.headers}
                getContrastYIQ={getContrastYIQ}
              />
            </ResizablePanel>
            <ResizableHandle />
            <ResizablePanel defaultSize={50} className="p-2">
              <EmailSettings
                t={t}
                data={data}
                control={control}
                setValue={setValue}
                isEmailAccountsLoading={isEmailAccountsLoading}
                emailAccounts={emailAccounts}
                validateEmailHeader={validateEmailHeader}
                headers={data?.headers}
              />
            </ResizablePanel>
          </ResizablePanelGroup>
        </ResizablePanel>

        <ResizableHandle withHandle className="bg-border" />

        <ResizablePanel defaultSize={70} className="border-x">
          <Card className="h-full border-0 rounded-none bg-background">
            <Controller
              name="mailContent"
              control={control}
              render={({ field }) => (
                <Card className="h-full border-none shadow-xl bg-white rounded-xl overflow-hidden p-2">
                  <CardContent className="p-0 h-[calc(100%-4rem)]">
                    <AdvancedEmailEditor
                      id="mailContent"
                      content={data?.mailContent}
                      onSetContent={(newContent) => {
                        const processedContent =
                          replaceHeaderTokens(newContent);
                        field.onChange(processedContent);
                      }}
                    />
                  </CardContent>
                </Card>
              )}
            />
          </Card>
        </ResizablePanel>

        <ResizableHandle withHandle className="bg-border" />

        <ResizablePanel defaultSize={15} minSize={10} className="border-l">
          <ResizablePanelGroup direction="vertical">
            <ResizablePanel defaultSize={90} className="p-2">
              <Card className="h-full border-none shadow-xl bg-white rounded-xl overflow-hidden">
                <CardHeader className="border-b bg-white/50 backdrop-blur-sm">
                  <CardTitle className="text-xl font-semibold text-gray-800">
                    {t("mailCompose.preview")}
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-0 h-[calc(100%-4rem)]">
                  <EmailPreview
                    previews={previews}
                    t={t}
                    from={data?.from}
                    selectedEmailAccountMail={emailAccountMail}
                    selectedEmailAccountName={emailAccountName}
                  />
                </CardContent>
              </Card>
            </ResizablePanel>

            <ResizablePanel defaultSize={10} className="border-t p-3">
              <div className="space-y-2">
                <Button
                  onClick={triggerSaveDocument}
                  className="w-full bg-primary hover:bg-primary/90"
                >
                  {t("documentDetails.saveDocument")}
                </Button>
              </div>
            </ResizablePanel>
          </ResizablePanelGroup>
        </ResizablePanel>

        <Dialog open={showSaveDialog} onOpenChange={setShowSaveDialog}>
          <DialogContent className="sm:max-w-md">
            <DialogHeader>
              <DialogTitle>{t("documentDetails.saveSuccess")}</DialogTitle>
            </DialogHeader>
            <div className="space-y-4 py-4">
              <p>{t("documentDetails.whatNext")}</p>
            </div>
            <DialogFooter className="flex space-x-2 sm:space-x-4">
              <Button
                variant="outline"
                onClick={() => setShowSaveDialog(false)}
              >
                {t("documentDetails.continueEditing")}
              </Button>
              <Button onClick={() => navigate(`/documents/${id}/send`)}>
                {t("documentDetails.goToSend")}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Dialog
          open={!!selectedTemplate}
          onOpenChange={() => setSelectedTemplate(null)}
        >
          <DialogContent className="max-w-[90vw] w-full h-[90vh] flex flex-col">
            <DialogHeader>
              <DialogTitle>{selectedTemplate?.name}</DialogTitle>
            </DialogHeader>
            <div className="flex-grow overflow-hidden">
              <iframe
                srcDoc={selectedTemplate?.mailContent}
                title={`Full preview of ${selectedTemplate?.name}`}
                className="w-full h-full border-none"
              />
            </div>
            <DialogFooter>
              <Button
                onClick={() => {
                  setValue("mailContent", selectedTemplate.mailContent);
                  setSelectedTemplate(null);
                }}
              >
                Use This Template
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </ResizablePanelGroup>
    </form>
  );
}
