import { BASEAPIURL } from "./constants";
import Axios from "axios";

const api_url = process.env.REACT_APP_API_URL;
class Api {
  getBaseUrl() {
    return api_url || BASEAPIURL;
  }
  getToken() {
    return localStorage.getItem("token");
  }

  getApi() {
    const axiosInstance = Axios.create({
      baseURL: BASEAPIURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.getToken(),
      },
    });
    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response?.status === 401) {
          // Clear token
          localStorage.removeItem("token");
          // Redirect to login
          window.location.href = "/login";
        }
        return Promise.reject(error);
      }
    );

    return axiosInstance;
  }
  getApiWithoutToken() {
    const axiosInstance = Axios.create({
      baseURL: BASEAPIURL,
      headers: {
        Accept: "application/json",
      },
    });

    return axiosInstance;
  }
}
export default new Api();
