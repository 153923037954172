import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { registerUser } from "../../../data";
import AuthStore from "../../../stores/AuthStore";
import {
  Button,
  Input,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui";
import { LogIn } from "lucide-react";

export function RegisterScreen() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    mutate: registerMutation,
    isLoading,
    error,
  } = useMutation({
    mutationKey: "register",
    mutationFn: (data) => registerUser(data),
    onSuccess: (response) => {
      AuthStore.setUser(response.data.token);
      navigate("/dashboard");
    },
  });

  const onSubmit = (data) => {
    registerMutation(data);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold mb-8 text-blue-600">Send Excel</h1>
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle>Hesap Oluştur</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <Controller
              name="name"
              control={control}
              rules={{ required: "İsim gereklidir" }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  placeholder="İsim"
                  error={errors.name?.message}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{ required: "E-posta gereklidir" }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="email"
                  placeholder="E-posta"
                  error={errors.email?.message}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: "Şifre gereklidir" }}
              render={({ field }) => (
                <Input
                  {...field}
                  type={showPassword ? "text" : "password"}
                  placeholder="Şifre"
                  error={errors.password?.message}
                />
              )}
            />
            <Button type="submit" className="w-full" disabled={isLoading}>
              {isLoading ? "Hesap oluşturuluyor..." : "Hesap Oluştur"}
            </Button>
          </form>

          {error && <p className="text-red-500 mt-2">{error.message}</p>}
        </CardContent>
        <CardFooter className="flex justify-center">
          <Button variant="link" className="flex items-center" asChild>
            <Link to="/login">
              <LogIn className="mr-2 h-4 w-4" />
              Giriş Yap
            </Link>
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}
