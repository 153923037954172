import api from "../api";

export const uploadExcel = (data) => {
  return api
    .getApi()
    .post(api.getBaseUrl() + "documents/upload", data)
    .then((res) => {
      return res.data;
    });
};

export const getDocument = (id) => {
  return api
    .getApi()
    .get(api.getBaseUrl() + "documents/" + id)
    .then((res) => {
      return res.data;
    });
};

export const updateDocument = (id, data) => {
  return api
    .getApi()
    .put(api.getBaseUrl() + "documents/" + id, data)
    .then((res) => {
      return res.data;
    });
};

export const getDocuments = () => {
  return api
    .getApi()
    .get(api.getBaseUrl() + "documents")
    .then((res) => {
      return res.data;
    });
};

export const deleteDocument = (id) => {
  return api
    .getApi()
    .delete(api.getBaseUrl() + "documents/" + id)
    .then((res) => {
      return res.data;
    });
};

export const createEmailTransaction = (id) => {
  return api
    .getApi()
    .post(api.getBaseUrl() + "documents/" + id + "/createEmailTransaction")
    .then((res) => {
      return res.data;
    });
};

export const getEmailTransactions = async (documentId, { take, skip } = {}) => {
  const params = new URLSearchParams();
  if (take) params.append("take", take);
  if (skip) params.append("skip", skip);

  return api
    .getApi()
    .get(
      api.getBaseUrl() +
        `documents/${documentId}/email-transactions?${params.toString()}`
    )
    .then((res) => {
      return res.data;
    });
};

export const getEmailTransactionsCount = async (documentId) => {
  return api
    .getApi()
    .get(api.getBaseUrl() + `documents/${documentId}/email-transactions/count`)
    .then((res) => {
      return res.data;
    });
};

export const sendMail = async (id) => {
  const response = await api
    .getApi()
    .post(api.getBaseUrl() + "documents/" + id + "/send-mail");
  return response.data;
};

export const updateEmailTransaction = async (id, transactionId, data) => {
  const response = await api
    .getApi()
    .put(
      api.getBaseUrl() + `documents/${id}/email-transactions/${transactionId}`,
      data
    );
  return response.data;
};

export const deleteEmailTransaction = async (id, transactionId) => {
  const response = await api
    .getApi()
    .delete(
      api.getBaseUrl() + `documents/${id}/email-transactions/${transactionId}`
    );
  return response.data;
};

export const uploadAttachments = async (id, formData) => {
  const response = await fetch(
    api.getBaseUrl() + "documents/" + id + "/attachments",
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: formData,
    }
  );

  if (!response.ok) {
    throw new Error("Failed to upload attachments");
  }

  return response.json();
};
