import { cn } from "@/lib/utils";
import { Spinner } from "./spinner";

interface SpinnerOverlayProps {
  show: boolean;
}

export function SpinnerOverlay({ show }: SpinnerOverlayProps) {
  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="flex flex-col items-center gap-2 rounded-lg bg-white p-4">
        <Spinner size="lg" />
        <p className="text-sm text-muted-foreground">Generating...</p>
      </div>
    </div>
  );
}
