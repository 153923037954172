import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AppLayout } from "../shared/layout";
import { addEmailAccount } from "../../../data";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui";
import { useTranslation } from "react-i18next";

export function MailAccountAddScreen() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const pageSettings = {
    title: t("emailAccountList.createNewEmailAccount"),
    actions: [
      {
        name: t("common.goToList"),
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          navigate("/email-accounts");
        },
      },
    ],
  };

  const mutation = useMutation({
    mutationKey: ["addEmailAccount"],
    mutationFn: (data) => {
      return addEmailAccount(data);
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(["emailAccounts"]);
      navigate("/emailAccounts");
    },
  });

  const onSubmit = async (data) => {
    mutation.mutate(data);
  };

  const accountType = watch("type");

  return (
    <AppLayout title={pageSettings.title} actions={pageSettings.actions}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="name"
          >
            {t("emailAccountList.name")}
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            placeholder="name"
            {...register("name", { required: "Name is required" })}
          />
          {errors.name && <p>{errors.name.message}</p>}
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="type"
          >
            {t("emailAccountList.type")}
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="type"
            {...register("type", { required: "Type is required" })}
          >
            <option value="resend">Resend</option>
            <option value="gmail">Gmail</option>
            <option value="smtp">SMTP</option>
          </select>
          {errors.type && <p>{errors.type.message}</p>}
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="dailyLimit"
          >
            {t("emailAccountList.dailyLimit")}
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="dailyLimit"
            type="number"
            min="1"
            placeholder={t("emailAccountList.dailyLimit")}
            {...register("dailyLimit", {
              required: t("emailAccountList.dailyLimitRequired"),
              min: {
                value: 1,
                message: t("emailAccountList.dailyLimitRequired"),
              },
              valueAsNumber: true,
            })}
            defaultValue={100}
          />
          {errors.dailyLimit && (
            <p className="text-red-500 text-xs italic">
              {errors.dailyLimit.message}
            </p>
          )}
          <p className="text-gray-500 text-xs mt-1">
            {t("emailAccountList.dailyLimitHelp")}
          </p>
        </div>

        {accountType === "resend" && (
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="apiKey"
            >
              {t("emailAccountList.apiKey")}
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="apiKey"
              type="text"
              placeholder="API Key"
              {...register("apiKey", { required: "API Key is required" })}
            />
            {errors.apiKey && <p>{errors.apiKey.message}</p>}
          </div>
        )}

        {accountType === "gmail" && (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="clientId"
              >
                {t("emailAccountList.clientId")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="clientId"
                type="text"
                placeholder="Client ID"
                {...register("clientId", { required: "Client ID is required" })}
              />
              {errors.clientId && <p>{errors.clientId.message}</p>}
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="clientSecret"
              >
                {t("emailAccountList.clientSecret")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="clientSecret"
                type="text"
                placeholder="Client Secret"
                {...register("clientSecret", {
                  required: "Client Secret is required",
                })}
              />
              {errors.clientSecret && <p>{errors.clientSecret.message}</p>}
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="refreshToken"
              >
                {t("emailAccountList.refreshToken")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="refreshToken"
                type="text"
                placeholder="Refresh Token"
                {...register("refreshToken", {
                  required: "Refresh Token is required",
                })}
              />
              {errors.refreshToken && <p>{errors.refreshToken.message}</p>}
            </div>
          </>
        )}

        {accountType === "smtp" && (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                {t("emailAccountList.email")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                placeholder="Email"
                {...register("email", { required: "Email is required" })}
              />
              {errors.email && <p>{errors.email.message}</p>}
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                {t("emailAccountList.password")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="password"
                placeholder="Password"
                {...register("password", { required: "Password is required" })}
              />
              {errors.password && <p>{errors.password.message}</p>}
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="host"
              >
                {t("emailAccountList.host")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="host"
                type="text"
                placeholder="Host"
                {...register("host", { required: "Host is required" })}
              />
              {errors.host && <p>{errors.host.message}</p>}
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="port"
              >
                {t("emailAccountList.port")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="port"
                type="number"
                placeholder="Port"
                {...register("port", { required: "Port is required" })}
              />
              {errors.port && <p>{errors.port.message}</p>}
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="ssl"
              >
                {t("emailAccountList.ssl")}
              </label>
              <input
                type="checkbox"
                className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="ssl"
                {...register("ssl")}
              />
              {errors.ssl && <p>{errors.ssl.message}</p>}
            </div>
          </>
        )}

        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            {t("common.add")}
          </button>
        </div>

        {accountType === "gmail" && <div className="mb-4"></div>}
      </form>
    </AppLayout>
  );
}
