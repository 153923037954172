import React from "react";
import { Card, CardContent } from "@/components/ui/card";
import { LandingPageLayout } from "../shared/layout";

export const PrivacyPolicyScreen = () => {
  return (
    <LandingPageLayout>
      <div className="container mx-auto py-16 px-4">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
          <p className="text-gray-600 mb-8">
            Effective Date: {new Date().toLocaleDateString()}
          </p>

          <div className="space-y-8">
            <Card>
              <CardContent className="pt-6">
                <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
                <p className="text-gray-600">
                  Welcome to Send Excel. Your privacy is important to us. This
                  Privacy Policy explains how we collect, use, and protect your
                  information when you use our web application, Send Excel,
                  which allows users to send bulk emails using data from Excel
                  files.
                </p>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="pt-6">
                <h2 className="text-2xl font-semibold mb-4">
                  2. Information We Collect
                </h2>
                <div className="space-y-4">
                  <div>
                    <h3 className="text-lg font-medium mb-2">
                      User Account Information
                    </h3>
                    <p className="text-gray-600">
                      When you sign up, we collect basic account details such as
                      your name, email address, and password.
                    </p>
                  </div>
                  <div>
                    <h3 className="text-lg font-medium mb-2">
                      Payment Information
                    </h3>
                    <p className="text-gray-600">
                      If you subscribe to our paid plans, payment information is
                      processed securely by third-party payment processors. We
                      do not store your credit card details.
                    </p>
                  </div>
                  <div>
                    <h3 className="text-lg font-medium mb-2">
                      Technical and Usage Data
                    </h3>
                    <p className="text-gray-600">
                      We collect log data, device information, and usage
                      analytics to improve our service and ensure system
                      security.
                    </p>
                  </div>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="pt-6">
                <h2 className="text-2xl font-semibold mb-4">
                  3. How We Use Your Information
                </h2>
                <ul className="list-disc list-inside space-y-2 text-gray-600">
                  <li>To provide and improve our services</li>
                  <li>To manage user accounts and authentication</li>
                  <li>
                    To respond to inquiries, customer support requests, and
                    technical issues
                  </li>
                  <li>To ensure compliance with legal obligations</li>
                  <li>To enhance security and prevent fraudulent activities</li>
                </ul>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="pt-6">
                <h2 className="text-2xl font-semibold mb-4">
                  4. Email Handling Policy
                </h2>
                <ul className="list-disc list-inside space-y-2 text-gray-600">
                  <li>
                    Send Excel does not store or read users' email content or
                    recipient lists
                  </li>
                  <li>
                    The system only processes email data for the purpose of
                    sending messages
                  </li>
                  <li>
                    Email content and recipient information are not retained
                    after the email has been sent
                  </li>
                </ul>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="pt-6">
                <h2 className="text-2xl font-semibold mb-4">
                  5. Data Security
                </h2>
                <p className="text-gray-600">
                  We implement industry-standard security measures to protect
                  user data, including encryption, secure data transmission, and
                  access control mechanisms. However, no method of electronic
                  transmission is 100% secure, and users should take necessary
                  precautions.
                </p>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="pt-6">
                <h2 className="text-2xl font-semibold mb-4">
                  6. Third-Party Services
                </h2>
                <ul className="list-disc list-inside space-y-2 text-gray-600">
                  <li>
                    We use third-party email providers to facilitate email
                    delivery
                  </li>
                  <li>
                    Payment transactions are handled by third-party payment
                    processors
                  </li>
                  <li>
                    We may use analytics tools to understand how users interact
                    with our platform
                  </li>
                </ul>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="pt-6">
                <h2 className="text-2xl font-semibold mb-4">
                  7. User Rights and Choices
                </h2>
                <ul className="list-disc list-inside space-y-2 text-gray-600">
                  <li>
                    Users can access, update, or delete their personal
                    information from their account settings
                  </li>
                  <li>Users can opt-out of marketing emails at any time</li>
                  <li>
                    If you wish to delete your account, you may request data
                    removal by contacting our support team
                  </li>
                </ul>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="pt-6">
                <h2 className="text-2xl font-semibold mb-4">
                  8. Compliance with Laws
                </h2>
                <p className="text-gray-600">
                  We comply with applicable data protection laws, including GDPR
                  and CCPA, where relevant. Users in certain jurisdictions may
                  have additional rights regarding their personal data.
                </p>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="pt-6">
                <h2 className="text-2xl font-semibold mb-4">
                  9. Changes to This Privacy Policy
                </h2>
                <p className="text-gray-600">
                  We may update this Privacy Policy from time to time. Users
                  will be notified of any significant changes via email or
                  in-app notifications.
                </p>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="pt-6">
                <h2 className="text-2xl font-semibold mb-4">10. Contact Us</h2>
                <p className="text-gray-600">
                  If you have any questions regarding this Privacy Policy,
                  please contact us at: support@sendexcel.com
                </p>
              </CardContent>
            </Card>
          </div>

          <p className="text-sm text-gray-500 mt-8">Last updated: 3/2/2025</p>
        </div>
      </div>
    </LandingPageLayout>
  );
};

export default PrivacyPolicyScreen;
